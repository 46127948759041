import React from "react"
import { Link, useNavigate } from "react-router-dom"

import Button from "../Button"
import Icon from "../Icon"
import { AWARD_CATEGORIES } from "pages/Home/components/AwardCategories"
import { toast } from "react-toastify"

const Footer = ({ isHeaderMobile = "", onClickItem }) => {
  const navigate = useNavigate()

  return (
    <footer className={`mx-auto w-full max-w-[1340px] bg-background px-4`}>
      <div
        className={`border-blueGradient relative py-16 before:top-0 md:py-12 ${isHeaderMobile ? "before:content-none md:-mt-6 md:pt-0" : ""}`}
      >
        <div className={"flex justify-between gap-x-[112px] md:flex-col md:gap-y-6"}>
          <div className={"space-y-6"}>
            <Link
              to="/"
              className={`z-10 flex items-center gap-x-4 text-left ${isHeaderMobile ? "md:hidden" : ""}`}
            >
              <img src={"/images/logo.png"} alt="Logo" className={"w-[120px] md:w-[80px]"} />
              <img src={"/images/logo-text.svg"} alt="Logo" className={"w-[280px] md:w-[160px]"} />
            </Link>
            <ul className={"flex gap-x-6 pl-[112px] md:pl-0"}>
              <li>
                <Link to={"/"} target={"_blank"}>
                  <Icon
                    name={"global"}
                    className={"fill-yellow transition-all duration-300 hover:fill-blue"}
                    size={32}
                  />
                </Link>
              </li>
              <li>
                <Link to={"mailto:quangcaosangtaovietnam@gmail.com"}>
                  <Icon
                    name={"sms"}
                    className={"fill-yellow transition-all duration-300 hover:fill-blue"}
                    size={32}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={"https://www.facebook.com/giaithuongquangcaosangtaovietnam2023"}
                  target={"_blank"}
                >
                  <Icon
                    name={"facebook"}
                    size={32}
                    className={"fill-yellow transition-all duration-300 hover:fill-blue"}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={"https://youtube.com/@QuangcaosangtaoVietNam2023?si=GVQor1ftEpk_Tqd8"}
                  target={"_blank"}
                >
                  <Icon
                    name={"youtube"}
                    size={32}
                    className={"fill-yellow transition-all duration-300 hover:fill-blue"}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={"https://www.linkedin.com/company/giải-thưởng-quảng-cáo-sáng-tạo-việt-nam"}
                  target={"_blank"}
                >
                  <Icon
                    name={"linkedin"}
                    size={32}
                    className={"fill-yellow transition-all duration-300 hover:fill-blue"}
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div
            className={
              "flex flex-1 justify-around gap-x-10 text-16 md:order-last md:flex-col md:gap-y-6"
            }
          >
            <ul className={"space-y-4 md:space-y-6"}>
              <li
                className={"cursor-pointer"}
                onClick={() => {
                  onClickItem && onClickItem()
                  navigate("/gioi-thieu-giai-thuong")
                }}
              >
                <span>Giới thiệu</span>
              </li>
              {AWARD_CATEGORIES.map((item) => (
                <li
                  className={"cursor-pointer"}
                  onClick={() => {
                    onClickItem && onClickItem()
                    navigate(item.url)
                  }}
                >
                  <span>{item.textVn}</span>
                </li>
              ))}
            </ul>
            <ul className={"space-y-4 md:space-y-6"}>
              <li
                className={"cursor-pointer"}
                onClick={() => {
                  onClickItem && onClickItem()
                  navigate("/chuyen-gia-dong-hanh")
                }}
              >
                <span>Chuyên gia đồng hành</span>
              </li>

              <li
                className={"cursor-pointer"}
                onClick={() => {
                  onClickItem && onClickItem()
                  navigate("/thu-vien-bai-thi")
                }}
              >
                <span>Thư viện bài thi</span>
              </li>
              <li
                className={"cursor-pointer"}
                onClick={() => {
                  onClickItem && onClickItem()
                  navigate("/danh-sach-thang-giai")
                }}
              >
                <span>Danh sách thắng giải</span>
              </li>
              <li
                className={"cursor-pointer"}
                onClick={() => {
                  onClickItem && onClickItem()
                  navigate("/tin-tuc-cuoc-thi")
                }}
              >
                <span>Tin tức / Sự kiện</span>
              </li>
            </ul>
          </div>
          <div className={"space-y-4 md:flex md:space-x-6 md:space-y-0"}>
            <Button
              onClick={() => {
                onClickItem && onClickItem()
                navigate("/dang-ky-du-thi")
              }}
            >
              <b>Đăng ký tham gia</b>
            </Button>
            <Button
              onClick={() => {
                onClickItem && onClickItem()
                navigate("/nop-bai-du-thi")
              }}
            >
              <b>Nộp bài thi</b>
            </Button>
          </div>
        </div>
        <p className={"mt-16 text-right text-14 text-gray md:mt-8 md:text-left"}>
          © Giải thưởng Quảng cáo Sáng tạo Việt Nam 2023. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
